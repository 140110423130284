@import "../../../styles/variables.module.scss";

$multicolumnCardPadding: 6px;
$singleColumnCardPadding: 0px;
$infoBarHorizontalPadding: $multicolumnCardPadding;

.card {
  position: relative;
  margin-top: $cardGap;
  background-color: #ffffff;
  border: 1px solid $lightGrayBorderColor;;
  border-radius: $cardBorderRadius;
  overflow: visible;
  padding: $multicolumnCardPadding;
}

.pendingDelete {
  background-color: RGBA(255, 255, 255, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.mediaView img, .mediaView video {
  display: block;
  width: 100%;
}

.card.singleColumn {
  padding: $singleColumnCardPadding;

  & .infoBar {
    margin-top: 3px;
    margin-bottom: 3px;
  }
}

.singleColumn .mediaContainer img, 
.singleColumn .mediaContainer video,
.singleColumn .mediaContainer div {
  border-radius: 4px 4px 0 0;
}

.photoStrip {
  margin-left: auto;
  margin-right: auto;
  max-width: 200px;
}

.mediaContainer img, .mediaContainer video {
  transition: opacity 0.4s ease-in-out;
  object-fit: contain;
  max-height: 100%;
}

.infoBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 38px;
  line-height: 38px;
  color: rgb(100, 100, 100);
  font-size: 1em;
  font-weight: 500;
  text-align: left;
  padding: 0 $infoBarHorizontalPadding;
}

.infoBar img {
  display: inline;
  width: 15px;
  height: 28px;
  object-fit: contain;
}

.multicolumn .infoBar {
  font-size: 0.85em;
  margin-top: $multicolumnCardPadding;
  height: 34px;
  line-height: 34px;
}

.shareButton {
  height: 40px;
  width: 40px;
  margin-top: -3px;
  margin-right: -$infoBarHorizontalPadding;
  border-radius: 50%;
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  transition: background-color 0.2s ease-in-out;
}

.singleColumn .shareButton {
  margin-top: -1px;
  margin-right: -4px;
}

.shareButton:hover {
  background-color: rgb(240, 240, 240);
}

.playIcon {
  $width: 90px;
  pointer-events: none;

  position: absolute;
  width: $width;
  left: 50%;
  top: 50%;
  margin-top: -$width / 2;
  margin-left: -$width / 2;

  opacity: 0.75;
  transform: scale(1);
  transition: all 0.2s ease-in-out !important;
}

.hover {
  transform: scale(1.06);
}

.clickable {
  cursor: pointer;
}

.opaque {
  opacity: 1;
}

.transparent {
  pointer-events: none;
  opacity: 0;
}
