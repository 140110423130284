@import "../../../styles/variables.module.scss";

.card {
  margin-top: $cardGap;
  border-radius: $cardBorderRadius;
  background-color: white;
  border: 1px solid $lightGrayBorderColor;
  padding: 6px;
}

.card img {
  width: 100%;
}