.showcase {
  position: relative;
  display: grid;
  place-items: center;
}

.placeholderContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.media {
  object-fit: contain;

  opacity: 0;
  transition: transform 300ms ease-in-out;

  background-repeat: no-repeat;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-duration: 600ms;
}

.mediaAppear {
  animation-name: mediaAppear;
  animation-play-state: running;
}

@keyframes mediaAppear {
  0% {
    transform: scale(0.75);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
