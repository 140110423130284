.container {
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;

  &.small {
    max-width: 600px;
    padding-left: 10px;
    padding-right: 10px;
  }

  &.medium {
    max-width: 960px;
  }

  &.large {
    max-width: 1280px;
  }
}
