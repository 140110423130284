.rotate {
  animation-duration: 25s;
  animation-iteration-count: infinite;
  animation-name: rotate;
  animation-timing-function: linear;
  animation-fill-mode: backwards;
}

.rotateReverse {
  animation-duration: 25s;
  animation-iteration-count: infinite;
  animation-name: rotateReverse;
  animation-timing-function: linear;
  animation-fill-mode: backwards;
}

$distance: 120px;
$degrees: 180deg;
$finalOpacity: 0.45;

@keyframes rotate {
  0% {
    transform: translateY(0) rotate(0);
    opacity: 0;
  }
  10% {
    opacity: $finalOpacity;
  }
  90% {
    opacity: $finalOpacity;
  }
  100% {
    opacity: 0;
    transform: translateY(-$distance) rotate($degrees);
  }
}

@keyframes rotateReverse {
  0% {
    transform: translateY(0) rotate(0);
    opacity: 0;
  }
  10% {
    opacity: $finalOpacity;
  }
  90% {
    opacity: $finalOpacity;
  }
  100% {
    opacity: 0;
    transform: translateY(-$distance) rotate(-$degrees);
  }
}
