@import "../../styles/variables.module.scss";

.primary {
  display: block;
  padding: 0 60px;
  margin: 0 auto;
  border-radius: 9px;
  cursor: pointer;
  height: 75px;
  font-size: 21px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 25px;
  transition: transform 0.2s;
  user-select: none !important;
  border: none;
  outline: none !important;
  color: rgb(255, 255, 255);
  background-color: #30aade;
  box-shadow: rgba(48, 170, 222, 70%) 0px 5px 0px;
}

.primary:hover {
  transform: scale(1.05);
}

.primary:active {
  opacity: 0.6;
}

@media only screen and (min-width: 620px) {
  button.primary {
    max-width: 435px;
  }
}

@mixin blueButton {
  border-width: 0;
  color: white;
  background-color: #30aade;
  // background: linear-gradient(90deg, #00D1F2 0%, #00A8E0 100%);
  font-size: 17px;
  font-weight: 600;
  transition: transform 0.2s;
  outline: none !important;
  box-shadow: none !important;
}

.blue {
  @include blueButton();

  height: 60px;
  line-height: 20px;
  border-radius: 30px;
  padding: 20px calc(min(5vw, 60px));
}

a.blue:visited {
  color: white;
}

.blueCompact {
  @include blueButton();

  height: 50px;
  line-height: 20px;
  border-radius: 25px;
  padding: 10px 30px;
}

.blue:hover, .blueCompact:hover {
  transform: scale(1.05);
  color: white;
}

.blue:active, .blueCompact:active {
  outline: none;
  opacity: 0.6;
}

.asLink {
  display: inline;
  background: none;
  color: $linkColor;
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
}

.asLink:hover {
  color: $linkHoverColor;
}