@import "../../../styles/variables.module.scss";

.card {
  position: relative;
  margin-top: $cardGap;
  border: 1px solid $lightGrayBorderColor;
  border-radius: 6px;
  overflow: hidden;
  padding: 40px;
  text-align: center;
  word-break: break-word;
}

.large {
  padding: 40px;
}

.small {
  padding: 20px;
}

.large .text, .small .text {
  font-weight: 600;
}

.large .text {
  font-size: 1.7rem;
  line-height: 2.3rem;
}

.small .text {
  font-size: 1.5rem;
  line-height: 1.9rem;
}

.callToAction {
  margin-top: 20px;
  padding: 10px 40px;
  height: 50px;
  border-radius: 25px;
  display: inline-block;
  text-decoration: none;
  line-height: 30px;
  font-weight: 600;
  font-size: 16px;
}

.callToAction:hover {
  text-decoration: none;
}

.callToAction:active {
  opacity: 0.8;
}

.headerImage {
  margin-bottom: 20px;
}

@media only screen and (max-width: 540px) {
  .card {
    padding: 20px;
  }
  .text {
    font-size: 2rem;
    line-height: 3rem;
  }
}


@media only screen and (max-width: 420px) {
  .card {
    padding: 30px 15px;
  }
  .text {
    font-size: 1.5rem;
    line-height: 2.2rem;
  }
}