@import "../../../styles/variables.module.scss";

.avatar {
  position: absolute;
  top: 10px;
  left: 12px;

  transition: transform 0.2s ease-in-out;

  animation: avatarIn ease-in-out 0.6s;
}

@keyframes avatarIn {
  0% {
    transform: scale(0);
  }
  70% {
    transform: scale(1.12);
  }
  100% {
    transform: scale(1);
  }
}

$size: 54px;
.avatar img {
  width: $size;
  height: $size;
  border: 2px solid #ffffff;
  border-radius: ($size /  2);
  @include dropShadow;
}

.avatar:hover {
  transform: scale(1.1);
}