@import "../../../styles/variables.module.scss";

.card {
  position: relative;
  margin: 40px auto 0 auto;
  background-color: #ffffff;
  border: 1px solid $lightGrayBorderColor;;
  border-radius: $cardBorderRadius;
  padding: 15px;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media screen and (max-width: 600px) {
  .card {
    padding: 0px 0px 15px 0px;

    & div, & img, & video {
      border-radius: 4px 4px 0 0;
    }
  }
}

.shareButton {
  margin-top: 15px;
  margin-bottom: 5px;
  color: white;
}

.aspectFitBox {
  position: relative;

  & img, & video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    transition: opacity 0.4s ease-in-out;
  }
}

.mediaView {
  @include dropShadow;
}

.opaque {
  opacity: 1;
}

.transparent {
  pointer-events: none;
  opacity: 0;
}

.playIconContainer {
  cursor: pointer;
}

.playIcon {
  $width: 120px;

  position: absolute;
  width: $width;
  left: 50%;
  top: 50%;
  margin-top: -$width / 2;
  margin-left: -$width / 2;

  opacity: 0.75;
  transform: scale(1);
  transition: all 0.2s ease-in-out !important;
}

.playIconContainer:hover .playIcon {
  transform: scale(1.1);
}