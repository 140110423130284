.fadeIn {
  animation: fadeIn ease-in-out 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

.hoverScale {
  transition: transform 0.2s ease-in-out;
}

.hoverScale:hover {
  transform: scale(1.02);
}

.placeholderShimmer {
  background-color: #ddd;
  animation: gradientScroll 2.5s linear infinite;
  background-image: linear-gradient(90deg, #ddd, #fff, #ddd, #fff, #ddd, #fff);
  background-size: 500% 100%;
}

@keyframes gradientScroll {
  0% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}