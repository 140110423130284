$cardGap: 30px;
$cardBorderRadius: 6px;
$lightGrayBorderColor: rgba(0, 0, 0, 0.13);

$pageBottomPadding: 120px;

$linkColor: gray;
$linkHoverColor: #30aade;

$darkTextColor: #222222;

@mixin cardStyle {
  background-color: white;
  border-radius: $cardBorderRadius;
  border: 1px solid $lightGrayBorderColor;
}

@mixin softDropShadow {
  box-shadow: 0 0 15px -9px rgba(0, 0, 0, 0.7);
}

@mixin dropShadow {
  box-shadow: 1px 1px 9px -5px rgba(0, 0, 0, 0.7);
}
