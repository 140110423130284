textarea.codeBlock {
  width: 100%;
  height: 120px;
  padding: 10px;
  border: none;
  background-color: #f4f4f4;
  border-radius: 4px;
  outline: none;
  resize: none;
  margin-bottom: 20px;
}