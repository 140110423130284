.container {
  position: absolute;
  top: 12px;
  right: 12px;
  display: flex;
}

.container > * {
  margin-right: 10px;
}

.container > *:last-child {
  margin-right: 0;
}

.privacyInfo {
  overflow: visible;
}

.privacyInfo img {
  height: 24px;
  margin-top: 13px;
  margin-right: 12px;
}

.privacyMessage {
  position: absolute;
  width: 166px;
  background-color: #fff;
  padding: 8px;
  font-size: 10px;
  top: 2px;
  left: -180px;
  box-shadow: 1px 1px 9px -5px rgba(0, 0, 0, 0.7);
  border-radius: 3px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.triangle {
  position: absolute;
  left: 100%;
  top: 42%;

  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-left: 5px solid #fff;
  border-bottom: 5px solid transparent;
}

.showPrivacyMessage {
  opacity: 1 !important;
}
