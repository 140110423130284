@import "../../styles/variables.module.scss";

.emptyState {
  text-align: center;
}

.card {
  @include cardStyle;
  padding: 12px 24px 24px 24px;
}

.fullscreen {
  display: grid;
  place-items: center;
  height: 94vh;
}

.emptyState h1 {
  margin-top: 40px;
  font-size: 24px;
  font-weight: 500;
}

.emptyState p {
  margin-top: 20px;
  font-size: 18px;
}

.emptyState button {
  margin-top: 20px;
}

.standardConfetti {
  width: 100%;
  height: clamp(90vw, 360px, 100vw);
}

@media screen and (max-width: 420px) {
  .emptyState h1 {
    margin-top: 20px;
  }
}