.header {
  margin-top: 40px;
  text-align: center;
}

.defaultLogo {
  max-width: 150px;
  cursor: pointer;
}

.heading {
  margin-top: 30px;
  font-weight: 600;
  word-wrap: break-word;
}

.subheading {
  font-weight: 500;
  margin-top: 15px;
}

.headerImage {
  max-height: 360px;
  cursor: pointer;
}

@media screen and (max-width: 1020px) {
  .header {
    margin-top: 74px;
  }
}