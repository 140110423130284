@import "../../../styles/variables.module.scss";

.controls {
  position: relative;
  margin-top: 30px;
  vertical-align: middle;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.controls > button {
  background-color: white;
  line-height: 50px;
  height: 50px;
}

.controls > *:nth-child(n + 2) {
  margin-left: 8px;
}

button.active, div.active {
  background-color: #30aade;
  color: white;
  border: 1px solid #30aade;
}

.activeText {
  color: white;
}

.inactiveText {
  color: $darkTextColor;
}

button.active svg, button.activeText > svg {
  fill: white;
}

button.inactive {
  background-color: white;
  color: $darkTextColor;
  border: 1px solid #cecece;
}

button.inactive svg, button.inactiveText > svg {
  fill: $darkTextColor;
}

.buttonCancelSvg {
  position: relative;
  top: -2px;
  height: 20px;
  width: 20px;
}

.calendarContainer {
  position: absolute;
  z-index: 1000;
  top: 60px;
  margin-left: 0px !important;
  transition: opacity 0.2s ease-out;
}

@media screen and (max-width: 420px) {
  .controls > button, .controls > div {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 14px;
  }
}
