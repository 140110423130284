.callout {
  position: fixed;
  top: 0;
  max-width: 100%;
  padding: 12px 17px 12px 17px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 100;

  background-color: white;

  display: flex;
  justify-content: center;
  box-shadow: 0px 0px 15px -9px rgba(26, 24, 24, 0.7);

  transform: translateY(-80px);
  opacity: 0;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.callout button {
  margin-left: 16px;
}

.show {
  transform: translateY(0px);
  opacity: 1;
}

.profileSummary {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  cursor: pointer;
}

$profileImageWidth: 50px;

@mixin centerVerticalFlex {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.profileImage {
  @include centerVerticalFlex;
  height: 100%;
}

.profileImage img {
  border-radius: ($profileImageWidth / 2);
  object-fit: cover;
  width: $profileImageWidth;
  height: $profileImageWidth;
}

.userAttributes {
  @include centerVerticalFlex;
  text-align: left;
  height: 100%;
  padding: 4px 10px;
}

.primaryAttribute {
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  word-break: break-word;
}

.secondaryAttribute {
  color: gray;
  font-size: 14px;
  font-weight: 500;
}

.buttonContainer {
  @include centerVerticalFlex;
  display: none;
}

@media (min-width: 450px) {
  .callout {
    border-radius: 15px;
    right: unset !important;
    left: 15px !important;
    top: 10px !important;
    margin: 0 auto;
    width: auto;
    justify-content: space-between;
  }
  .buttonContainer {
    display: flex;
  }
}
