@import "../../styles/variables.module.scss";

.logo {
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 9px 20px 14px 12px;
  border-radius: 0 0 24px 0;
  background-color: white;
  transform-origin: top left;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
}

.logo:hover {
  transform: scale(1.06);
}

.logo img {
  width: 80px;
}

.page {
  padding-bottom: $pageBottomPadding;
}

.coverPhoto {
  position: relative;
  height: calc(min(33vh, 300px));
  background-color: #efefef;
  background-size: cover;
  background-position: center;
  border-bottom: 1px solid #efefef;
}

.userInfo {
  padding-top: 20px;

  display: flex;
  justify-content: space-between;
}

.groupLeft {
  display: flex;
  animation: avatarIn 1s ease-in-out;
}

@keyframes avatarIn {
  from {
    transform: translateX(-20px);
  }
  to {
    transform: translateX(0px);
  }
}

.actionButton {
  animation: actionButtonIn 1s ease-in-out;
  flex-shrink: 0;
  text-align: center;
}

a.actionButton:visited {
  color: white;
}

@keyframes actionButtonIn {
  from {
    transform: translateX(20px);
  }
  to {
    transform: translateX(0px);
  }
}

$profileImageWidth: 120px;
$profileImageWidthMobile: 90px;

.avatar {
  height: $profileImageWidth;
  width: $profileImageWidth;
  flex-shrink: 0;
  border-radius: ($profileImageWidth / 2);
  background-color: white;
  background-size: cover;
  background-position: center center;
  box-shadow: 0 6px 14px 0 rgba(0, 0, 0, 0.2);

  margin-right: 20px;

  position: relative;
  margin-top: -40px;
  cursor: pointer;
}

@media screen and (max-width: 640px) {
  .userInfo {
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
  }
  .actionButton {
    display: block;
    margin: 30px auto 10px auto;
    width: 90%;
    max-width: 240px;
  }
  .avatar {
    height: $profileImageWidthMobile;
    width: $profileImageWidthMobile;
    margin-right: 15px;
    margin-top: -30px;
  }
}

@media screen and (max-width: 480px) {
  .coverPhoto {
    margin-top: 68px;
  }
  .logo {
    top: 9px;
  }
}

.name {
  font-size: 24px;
  margin-bottom: 0;
  word-break: break-word;
}

.username {
  font-size: 17px;
  color: #999;
}

.description {
  color: $darkTextColor;
}

.tidbits {
  margin-top: 6px;
  line-height: 22px;
  overflow: hidden;
}

.tidbits span {
  margin: 10px 20px 0 0;
  float: left;
}

.tidbits svg {
  height: 16px;
  margin-right: 6px;
  margin-bottom: 2px;
  fill: $darkTextColor;
}

.description {
  margin-top: 25px;
}

.description p {
  margin: 8px 0;
}

.galleries {
  margin-top: 60px;
  clear: both;
}

.websiteButton {
  margin-top: -7px;
}
