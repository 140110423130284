@import "../../../../styles/variables.module.scss";

.galleryCard {
  overflow: hidden;
  color: $darkTextColor;
  
  @include cardStyle;
  @include softDropShadow;

  transition: transform 0.2s ease-in-out, opacity 0.4s ease-out;
}

.galleryCard:hover {
  transform: scale(1.012);
}

.loaded {
  margin-top: 40px;
  opacity: 1;
}

.loading {
  opacity: 0;
}

.showcase video, .showcase img {
  object-fit: contain;
  object-position: center center;
}

.cardHeader {
  padding: 30px 30px 20px 30px;
}

.cardHeader h3 {
  color: $darkTextColor;
  font-size: 22px;
  margin-bottom: 0;
  line-height: 1.2;

  margin-block-end: 0;
}

.cardHeader span.divider {
  color: lightgray;
  padding: 0 15px;
}

.cardHeader {
  font-size: 16px;
  color: gray;
  font-weight: 500;
  text-align: left;
}

.spaceBetween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

.secondaryInfo {
  margin-top: 5px;
}

.secondaryInfo > span:last-child {
  min-width: 240px;
  text-align: right;
}

.happeningNow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #c930de;
  border-radius: 3px;
  color: white;
  padding: 8px 15px;
  font-size: 13px;
  font-weight: 600;
  margin-top: -8px;
  text-transform: uppercase;

  animation: pulse 3.2s linear infinite;
  background-image: linear-gradient(90deg, #c930de, #e95bfc, #c930de, #e95bfc, #c930de, #e95bfc);
  background-size: 500% 100%;
}

@keyframes pulse {
  0% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}

@media screen and (max-width: 640px) {
  .secondaryInfo {
    flex-direction: column;
  }
  .secondaryInfo > span:first-child {
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .secondaryInfo > span:last-child {
    text-align: left;
  }
}