.container {
  position: absolute;
  top: 9px;
  right: 10px;
  z-index: 1000;

  display: flex;
}

.container > * {
  margin-right: 10px;
}

.container > :last-child {
  margin-right: 0;
}
