
.actionGroup {
  position: absolute;
  overflow: visible;
  width: 60px;
  top: 20px;
  left: 20px;
  pointer-events: all;
}

.toggleButton {
  height: 36px;
  width: 60px;
  border-radius: 18px;
  background-color: #fff;
  opacity: 0;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.20);
  background-size: 33%;
  background-repeat: no-repeat;
  background-position: center 51%;
  transition: opacity 0.2s ease-in-out;
}

.dropdown {
  position: absolute;
  z-index: 50;
  top: 48px;
  left: 0;
  width: 204px;
  padding: 15px 12px;
  overflow: hidden;
  background-color: #fff;
  border-radius: 9px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  transition: all 0.2s ease-in-out;
}

.dropdownIn {
  opacity: 1;
  transform: translateY(0px);
}

.dropdownOut {
  transform: translateY(-12px);
  pointer-events: none;
  opacity: 0;
}

.opaque {
  opacity: 1;
}

.transparent {
  pointer-events: none;
  opacity: 0;
}

.dropdownItem:first-child {
  padding-top: 5px;
}

.dropdownItem:last-child {
  padding-bottom: 5px;
}

.dropdownItem {
  display: block;
  font-size: 17px;
  height: 50px;
  padding: 0 0 0 45px;
  text-align: left;
  width: 100%;
  background-color: transparent;
  opacity: 0.8;
  border-radius: 4px;
  background-size: auto 50%;
  background-repeat: no-repeat;
  background-position: 10px 45%;
  transition: background-color 0.2s ease-in-out;
}

.dropdownItem:hover {
  background-color: rgb(240, 240, 240);
}

.destructive {
  color: RGBA(154, 47, 47);
  padding-top: 5px;
}

.destructive:hover {
  background-color: RGBA(186, 27, 27, 0.10);
}