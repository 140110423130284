.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: RGBA(0, 0, 0, 0.8);
  z-index: 9000;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
}

.show {
  opacity: 1;
  pointer-events: all;
}

.mediaView {
  transform: scale(0.9);
  opacity: 0;
  transition: all 0.3s ease-out;
}

.mediaView img, .mediaView video {
  width: 100%;
}

.show .mediaView.loaded {
  opacity: 1;
  transform: scale(1);
}

.closeButton {
  $size: 30px;

  position: fixed;
  top: 15px;
  right: 15px;
  width: $size;
  height: $size;
  cursor: pointer;

  transition: all 0.2s ease-in-out;
}

.closeButton:hover {
  transform: scale(1.1);
}
