@import "../../../styles/variables.module.scss";

.newPhotosButton {
  position: fixed;
  width: 200px;
  left: 50%;
  bottom: 15px;
  margin-left: -100px;
  z-index: 1000;
  border-radius: 25px;
  height: 50px;
  font-weight: 500;
  background-color: white;
  box-shadow: 5px 5px 15px -9px rgba(0, 0, 0, 0.7);
  transform: scale(0.5) translateY(-20px);
  opacity: 0;

  color: $darkTextColor;
  transition: all 0.3s ease-in-out;
}

.newPhotosButton:hover {
  transform: scale(1.05) translateY(0px);
}

.show {
  transform: scale(1.0) translateY(0px);
  opacity: 1;
}

.hide {
  transform: scale(0.5) translateY(-20px);
  opacity: 0;
  pointer-events: none;
}
