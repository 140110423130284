@import "../../styles/variables.module.scss";

.toast {
  position: fixed;
  z-index: 9999;
  bottom: 15px;
  left: 15px;
  font-size: 14px;

  width: 100%;
  max-width: 520px;
  padding: 30px;
  background-color: white;
  border-radius: 4px;

  border-left: 15px solid #da4a4a;
  display: flex;
  justify-content: space-between;

  transition: all 0.4s ease-in-out;

  @include softDropShadow;
}

.toast.info {
  border-left: 15px solid #dedede;
}

.toast.error {
  border-left: 15px solid #da4a4a;
}

.toast button {
  background: url(../../image/close-round.svg);
  border: none;
  width: 36px;
  min-width: 30px;
  height: 36px;
  margin-left: 8px;
  background-repeat: no-repeat;
  background-size: 100%;
  align-self: center;

  transition: transform 0.2s ease-out;
}

.toast button:hover {
  transform: scale(1.08);
}

.show {
  transform: translateY(0px);
  opacity: 1;

  animation-name: appear;
  animation-duration: 0.4s;
  animation-timing-function: ease-in-out;
}

.hide {
  transform: translateY(40px);
  opacity: 0;
  pointer-events: none;
}

@keyframes appear {
  0% {
    transform: translateY(40px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@media screen and (max-width: 550px) {
  .toast {
    right: 15px;
    width: unset;
    max-width: unset;
  }
}