@import "../../styles/variables.module.scss";

@mixin pillShape {
  display: inline-block;
  height: 50px;
  border-radius: 25px;
  line-height: 50px;
  vertical-align: middle;

  padding: 0 20px 0 20px;
  vertical-align: middle;
  font-size: 16px;

  border-width: 1px;
  border-style: solid;

  transition: transform 0.2s ease-in-out;
}

.multibutton {
  height: 50px;
  padding: 0 20px 0 20px;
  vertical-align: middle;
  font-size: 16px;

  border-radius: 25px;
  border-width: 1px;
  border-style: solid;
  border-color: #cecece;

  background-color: white;
  color: $darkTextColor !important;
  
  transition: transform 0.2s ease-in-out;
}

.innerMultibutton {
  background-color: transparent;
  line-height: 50px;
  font-weight: 500;
}

.pillButton {
  @include pillShape();

  border-color: #cecece;
  background-color: white;
  color: $darkTextColor;
  font-weight: 500;
}

.pillButton:visited {
  color: $darkTextColor;
}

.bluePillButton {
  @include pillShape();

  background-color: #30aade;
  border-color: #30aade;
  color: white !important;
  font-weight: 600;
}

.svg, .pillButton img, .pillButton svg, .bluePillButton img, .bluePillButton svg {
  position: relative;
  top: -2px;
  margin-right: 10px;
  height: 20px;
  max-width: 24px;
}

.pillButton:hover, .bluePillButton:hover, .multibutton:hover {
  transform: scale(1.05);
}

a.pillButton:hover, a.bluePillButton:hover {
  color: $darkTextColor;
}

@media screen and (max-width: 480px) {
  .mobileCollapse {
    padding-left: 5px;
    padding-right: 5px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mobileCollapse span {
    display: none;
  }

  .pillButton.mobileCollapse img, .pillButton.mobileCollapse svg, .bluePillButton.mobileCollapse img, .bluePillButton.mobileCollapse svg {
    margin-right: 0;
    margin-top: 2px;
  }
  
}