@import "../../styles/variables.module.scss";

.introduction {
  margin-top: 100px;
  text-align: center;
}

.introduction a {
  position: relative;
  top: -2px;

  display: inline-block;
  height: 32px;
  width: 32px;
  margin-left: 4px;
  border-radius: 50%;
  
  transition: background-color 0.2s ease-in-out;
}

.introduction svg {
  width: 18px;
  margin-top: 2px;
  fill: #979797;
}

.introduction a:hover {
  background-color: rgba(235, 235, 235, 0.4);
}