.mediaPlaceholder {
  position: relative;
}

.mediaPlaceholder > * {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  width: 100% !important;
  transition: opacity 0.4s ease-in-out;
}
