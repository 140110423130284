@import "styles/variables.module.scss";

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $darkTextColor;
  font-weight: 500;
}

button {
  box-sizing: border-box;
  overflow: visible;
  text-transform: none;
  cursor: pointer;
  outline: none;
  border: none;
  padding-top: 0; // Safari fix
}

.modal-content {
  border-radius: 10px;
}

img, video {
  max-width: 100%;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
}

h1, h2, h3, h4, h5, p {
  color: $darkTextColor;
}

a, a:visited {
  color: $linkColor;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: $linkHoverColor;
}
